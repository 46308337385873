import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Popover,
  OverlayTrigger,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import UserContext from "../../contexts/UserContext";

const VacatePage = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [moveOutMonth, setMoveOutMonth] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showVacateButton, setShowVacateButton] = useState(true);
  const [tenantRoom, setTenantRoom] = useState("");
  const { user } = useContext(UserContext);
  const [isInMovingOut, setIsInMovingOut] = useState(false);
  const [moveOutRecords, setMoveOutRecords] = useState([]);

  useEffect(() => {
    const checkMovingOutStatus = async () => {
      try {
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/movingout/check",
          { name: user }
        );

        if (response.data.success) {
          setIsInMovingOut(response.data.inMovingOut);
          setShowTable(response.data.inMovingOut);
          setShowVacateButton(!response.data.inMovingOut);
        }
      } catch (error) {
        console.error("Error checking moving out status:", error);
      }
    };

    const fetchMoveOutRecords = async () => {
      try {
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/movingout/user",
          { name: user }
        );
        if (response.data.success) {
          setMoveOutRecords(response.data.records);
        }
      } catch (error) {
        console.error("Error fetching move-out records:", error);
      }
    };

    checkMovingOutStatus();
    fetchMoveOutRecords();
  }, [user]);

  const handleVacateClick = () => {
    setShowPopover(true);
  };

  const handleMonthSelect = (e) => {
    setMoveOutMonth(e.target.value);
    setShowConfirm(true);
    setShowPopover(false);
  };

  const handleConfirm = async () => {
    try {
      // Fetch the tenant's room
      const nameData = {
        name: user,
      };
      const roomResponse = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/tenant/room",
        nameData
      );

      if (roomResponse.data.success) {
        const room = roomResponse.data.room;

        // Add the move-out record
        const movingoutAdd = {
          date: new Date().toISOString().split("T")[0],
          month: moveOutMonth,
          room: room,
          name: user,
        };
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/movingout/add",
          movingoutAdd
        );

        if (response.data.success) {
          setShowTable(true);
          setShowConfirm(false);
          setShowVacateButton(false);
          setTenantRoom(room);
          // Fetch the updated move-out records
          const updatedRecordsResponse = await axios.post(
            "https://noidhousingbackend.mathenoid.com/api/movingout/user",
            { name: user }
          );
          if (updatedRecordsResponse.data.success) {
            setMoveOutRecords(updatedRecordsResponse.data.records);
          }
        } else {
          alert("Failed to add move-out record");
        }
      } else {
        alert("Failed to fetch tenant's room");
      }
    } catch (error) {
      console.error("Error adding move-out record:", error);
      alert("Error adding move-out record");
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setShowPopover(false);
  };

  const handleRevoke = async () => {
    try {
      // Remove the move-out record
      const response = await axios.delete(
        "https://noidhousingbackend.mathenoid.com/api/movingout/remove",
        { data: { user: user } }
      );

      if (response.data.success) {
        setShowTable(false);
        setShowVacateButton(true);
        // Fetch the updated move-out records
        const updatedRecordsResponse = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/movingout/user",
          { name: user }
        );
        if (updatedRecordsResponse.data.success) {
          setMoveOutRecords(updatedRecordsResponse.data.records);
        }
      } else {
        alert("Failed to revoke move-out record");
      }
    } catch (error) {
      console.error("Error revoking move-out record:", error);
      alert("Error revoking move-out record");
    }
  };

  const vacatePopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Select Move Out Month</Popover.Header>
      <Popover.Body>
        <Form.Select aria-label="Select month" onChange={handleMonthSelect}>
          <option>Select Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Form.Select>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="container mt-5">
      {showVacateButton && (
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={vacatePopover}
          show={showPopover}
        >
          <Button variant="primary" onClick={handleVacateClick}>
            Vacate
          </Button>
        </OverlayTrigger>
      )}

      <Modal className="mt-5" show={showConfirm} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Move Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to move out in {moveOutMonth}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {showTable && (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Name</th>
                <th>Room</th>
                <th>Date</th>
                <th>Move Out Month</th>
              </tr>
            </thead>
            <tbody>
              {moveOutRecords.map((record, index) => (
                <tr key={index}>
                  <td>{record.name}</td>
                  <td>{record.room}</td>
                  <td>{new Date(record.date).toLocaleDateString()}</td>
                  <td>{record.month}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="danger" className="mt-3" onClick={handleRevoke}>
            Revoke Moveout
          </Button>
        </>
      )}
    </div>
  );
};

export default VacatePage;
