import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./MyPaymentsTable.css";
import UserContext from "../../contexts/UserContext";

const PaymentsTable = () => {
  const { phoneNumber } = useContext(UserContext);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const phoneNumberData = {
          phoneNumber: "254" + phoneNumber.slice(1),
        };
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/payments",
          phoneNumberData
        );
        if (response.data.success) {
          setPayments(response.data.payments);
        } else {
          console.error(response.data.errors);
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, [phoneNumber]);

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Receipt</th>
            <th scope="col">Payment</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>{new Date(payment.date).toLocaleDateString()}</td>
              <td>{payment.receipt}</td>
              <td>{payment.payment}</td>
              <td>{payment.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;
