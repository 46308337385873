import React, { useState, useEffect, useContext } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import "./NotificationsTable.css";
import UserContext from "../../contexts/UserContext";

const NotificationsTable = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const userData = {
          name: user,
        };
        const response = await axios.post(
          "https://noidhousingbackend.mathenoid.com/api/tenant/notifications",
          userData
        );

        if (response.data.success) {
          setNotifications(response.data.notifications);
        } else {
          setError(response.data.errors);
        }
      } catch (error) {
        setError("Error fetching notifications");
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center pt-5 align-items-center fs-5 text-danger">
        oops, {error}
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Notifications</h2>
      <div className="table-container">
        <Table className="table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>From</th>
              <th>Message</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{notification.source}</td>
                <td>{notification.message}</td>
                <td>{formatDate(notification.date)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default NotificationsTable;
