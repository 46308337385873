import React, { useContext, useState, useEffect } from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./DashboardInitial.css";
import Card from "../../Components/Card/Card";
import DP from "../../Assets/Images/person.png";
import CalendarEvents from "../../Components/CalendarEvents/CalendarEvents";
import { Typography } from "@mui/material";
import UserContext from "../../contexts/UserContext";
import axios from "axios";

const DashboardInitial = () => {
  const { user } = useContext(UserContext);
  const [vacantRooms, setVacantRooms] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [events, setEvents] = useState([]);
  const [arrears, setArrears] = useState(0); // New state for arrears
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch(
          "https://noidhousingbackend.mathenoid.com/vacantrooms"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch vacant rooms");
        }
        const data = await response.json();
        setVacantRooms(data.length); // Set the number of vacant rooms
      } catch (error) {
        console.error("Error fetching vacant rooms:", error);
        setError(error.message);
      }
    };

    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          "https://noidhousingbackend.mathenoid.com/api/tenant/notifications/count/today",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: user }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch notifications");
        }
        const data = await response.json();
        setNotifications(data.count); // Set the number of notifications for the current date
      } catch (error) {
        console.error("Error fetching notifications:", error);
        setError(error.message);
      }
    };

    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/events"
        );
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
        setError(error.message);
      }
    };

    const fetchArrears = async () => {
      try {
        const response = await fetch(
          "https://noidhousingbackend.mathenoid.com/api/tenant/arrears",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: user }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch arrears");
        }
        const data = await response.json();
        setArrears(data.totalArrears); // Set the total arrears
      } catch (error) {
        console.error("Error fetching arrears:", error);
        setError(error.message);
      }
    };

    fetchRooms();
    fetchNotifications();
    fetchEvents();
    fetchArrears(); // Fetch arrears
  }, [user]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="dashboardContents">
        <div className="leftDashboardContents">
          <div className="name">Dashboard</div>
          <div className="welcome">
            <div className="welcomeRight">
              <span className="title">Welcome back {user}</span>
              <p className="welcomeText">
                We're delighted to have you. Need help on system walk through?
                Navigate to virtual assistant on the side menu
              </p>
            </div>
            <div className="welcomeImg">
              <img src={DP} alt="nyagah" width="70px" height="70px" />
            </div>
          </div>
          <div className="eventsBox">
            <div className="eventTitleCalendar">
              <div className="eventTitle">
                <p className="title">Events</p>
              </div>
              <div className="calendar">
                <CalendarEvents events={events} />
              </div>
            </div>

            <div className="key">
              {events.map((event) => (
                <div key={event.id} className="keyElement">
                  {event.emoji} {event.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="rightDashboardContents">
          <Card name="Arrears" number={`KSh ${arrears}`} />
          <Card name="Vacant Rooms" number={vacantRooms} />
          <Card name="Notifications" number={notifications} />
        </div>
      </div>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{
          color: "#69c37b",
          fontWeight: "bolder",
          fontSize: "15px",
        }}
      >
        Welcome {user}
      </Typography>{" "}
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{
            marginTop: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DashboardInitial;
