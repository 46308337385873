import React, { useState, useEffect } from "react";
import { Container, Button, Form, Table, Alert } from "react-bootstrap";
import "./NotifyComponent.css"; // Custom CSS file for additional styling
import axios from "axios";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";

const NotifyPage = () => {
  // State variables
  const [destination, setDestination] = useState("caretaker"); // 'caretaker' or 'landlord'
  const [notification, setNotification] = useState(""); // Message state
  const [notifications, setNotifications] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValid, setFormValid] = useState(false); // State to track form validity
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useContext(UserContext);

  // Fetch phone number to send SMS to
  async function fetchPhoneNumber(destination) {
    try {
      let url = "";
      if (destination === "landlord") {
        url = "https://noidhousingbackend.mathenoid.com/api/landlord/phone";
      } else if (destination === "caretaker") {
        url = "https://noidhousingbackend.mathenoid.com/api/caretaker/phone";
      }

      const response = await axios.get(url);
      return response.data.phoneNumber;
    } catch (error) {
      console.error("Error fetching phone number:", error);
      throw error;
    }
  }

  // Fetch notifications for the current user
  async function fetchNotifications() {
    try {
      // Fetch the tenant's room using the user's name
      const roomResponse = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/tenant/room",
        { name: user }
      );

      if (!roomResponse.data.success) {
        throw new Error(
          roomResponse.data.errors || "Unknown error fetching tenant room"
        );
      }

      const room = roomResponse.data.room;

      // Fetch notifications where the source is the current user's room
      const notificationsResponse = await axios.get(
        `https://noidhousingbackend.mathenoid.com/notifications/${room}`
      );

      setNotifications(notificationsResponse.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleConfirm = async () => {
    try {
      // Fetch the phone number of the destination (landlord or caretaker)
      const phoneNumber = await fetchPhoneNumber(destination);
      console.log("Fetched Phone Number:", phoneNumber);

      // Prepare the data to fetch the tenant's room
      const nameData = {
        name: user,
      };
      console.log("name Data:", nameData);

      // Fetch the tenant's room using the phone number
      const senderTenantRoomResponse = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/tenant/room",
        nameData
      );

      if (!senderTenantRoomResponse.data.success) {
        throw new Error(
          senderTenantRoomResponse.data.errors ||
            "Unknown error fetching tenant room"
        );
      }

      const senderTenantRoom = senderTenantRoomResponse.data.room;
      const notificationComplete = senderTenantRoom + " " + notification;

      // Prepare the data to send the notification
      const notificationData = {
        message: notificationComplete,
        mobile: phoneNumber,
      };

      // Prepare the data to save the notification in the database
      const notificationsDataSet = {
        message: notification,
        destination: destination,
        userName: user,
        forWho: "tenant",
      };

      // Send the notification to the backend
      const notifyResponse = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/notify",
        notificationsDataSet
      );
      console.log("Notification Response:", notifyResponse.data);

      // Send the SMS
      const smsResponse = await axios.post(
        "https://noidhousingbackend.mathenoid.com/sendsms",
        notificationData
      );
      console.log("SMS Response:", smsResponse.data);

      // Set success message
      setSuccessMessage("Notification sent successfully!");

      // Add the new notification to the list
      const newNotification = {
        id: notifications.length + 1,
        destination: destination,
        message: notification,
      };
      setNotifications([...notifications, newNotification]);

      // Reset form fields
      setNotification("");
      setShowConfirmation(false);

      // Show success message for 3 seconds
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Failed to send notification.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (notification.trim() !== "") {
      setShowConfirmation(true);
      setFormValid(true); // Mark the form as valid
    } else {
      setFormValid(false); // Mark the form as invalid
    }
  };

  // Handle cancel (clear message)
  const handleCancel = () => {
    setNotification("");
    setShowConfirmation(false);
    setFormValid(false); // Clear form validity state on cancel
  };

  return (
    <Container className="notify-page mt-4" style={{ maxWidth: "70%" }}>
      {successMessage && (
        <Alert variant="success" className="mt-4 text-center">
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert variant="danger" className="mt-4 text-center">
          {errorMessage}
        </Alert>
      )}
      <h2 className=" mb-4">Notify</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Choose destination:</Form.Label>
          <Form.Control
            as="select"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            <option value="caretaker">Caretaker</option>
            <option value="landlord">Landlord</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Message:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={notification}
            onChange={(e) => setNotification(e.target.value)}
            isInvalid={!formValid && notification.trim() === ""} // Show validation state
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter a message.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="my-4">
          <Button variant="primary" type="submit">
            Send
          </Button>
        </div>
      </Form>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="confirmation-modal mt-4">
          <Alert variant="info">
            <p className="text-center">
              Are you sure you want to send this to {destination}?
            </p>
            <div className="text-center">
              <Button onClick={handleConfirm} variant="success">
                Confirm
              </Button>{" "}
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Alert>
        </div>
      )}

      {/* Notification Table */}
      {notifications.length > 0 && (
        <div className="notifications mt-4">
          <h3 className=" my-5">Sent Notifications:</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>To</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{notification.destination}</td>
                  <td>{notification.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default NotifyPage;
